import WorkItem from "../components/WorkItem";
const Projects = ()=>{
    return(
        <div className="max-w-screen-2xl mx-auto pt-10 px-5 pb-10">
            <div className="border-b-2  border-lime-500 mb-5">
                <text className="bg-lime-600 py-1 px-3 font-bold text-slate-200 md:text-4xl text-2xl">PROJECTS & SERVICES</text>
            </div>
            <div className="grid md:grid-cols-2 py-2">
                <WorkItem title="Landscape Architectural Master Planning"/>
                <WorkItem title="Environmental Planning"/>
                <WorkItem title="Landscape Architectural Design Concepts"/>
                <WorkItem title="Streetscape Design"/>
                <WorkItem title="Sketch Plans and Planting Plans"/>
                <WorkItem title="Working Drawings and As-Built Plans"/>
                <WorkItem title="Tender Drawings"/>
                <WorkItem title="Detail Project Costing"/>
                <WorkItem title="Project Management"/>
                <WorkItem title="Urban Design and Layouts"/>
                <WorkItem title="Council Submissions and Approvals"/>
            </div>

            {/* <div className="grid grid-cols-3 mt-4 border shadow-md rounded">
                <div className="w-full h-96 bg-cover bg-center" style={{backgroundImage:"url(./assets/project/Rocco1.jfif)"}}></div>
                <div className="w-full h-96 bg-gradient-to-t from-lime-800 via-lime-600 to-lime-900  flex justify-center items-center font-bold text-lg text-slate-200 text-center p-3">
                    Our work at Rocco Mama 3 months post Installation
                </div>
                <div className="w-full h-96 bg-cover bg-center" style={{backgroundImage:"url(./assets/project/Rocco2.jfif)"}}></div>

                <div className="w-full h-96 bg-gradient-to-t from-lime-800 via-lime-600 to-lime-900 flex justify-center items-center font-bold text-lg text-slate-200 text-center p-3">
                    Our Installation Team & Maintainance are Goated
                </div>
                <div className="w-full h-96 bg-cover bg-center" style={{backgroundImage:"url(./assets/project/Rocco3.jfif)"}}></div>
                <div className="w-full h-96 bg-gradient-to-t from-lime-800 via-lime-600 to-lime-900 flex justify-center items-center font-bold text-lg text-slate-200 text-center p-3">
                    They have Green Hands
                </div>

                <div className="w-full h-96 bg-cover bg-center" style={{backgroundImage:"url(./assets/project/Rocco4.jfif)"}}></div>
                <div className="w-full h-96 bg-gradient-to-t from-lime-800 via-lime-600 to-lime-900 flex justify-center items-center font-bold text-lg text-slate-200 text-center p-3">
                    Let us help you with your landscape Designs & Installations
                </div>
                <div className="w-full h-96 bg-cover bg-center" style={{backgroundImage:"url(./assets/project/Rocco5.jfif)"}}></div>
            </div> */}

            {/* <div className="flex w-full mt-20 space-x-3 justify-between">
                <div className="w-[80%]">
                    <img src="/assets/project/sketch/sketch4.jpg" alt="" />
                </div>
                <div className="flex flex-col">
                    <h3 className="text-xl font-bold">LANDSCAPE CONCEPT DESIGN</h3>
                    <p className="text-lg">Our mandate with this project was straight-forward... To create a concept design of park whereby adults and children can be accommodated! Our dedicated team 
                        was hard at work and managed to make this a dream-come-true for our clients and everyone was happy!
                    </p>
                </div>
            </div> */}

            <div className="flex md:mt-36 mt-14">
                <div id="previous" className="flex justify-center items-center hidden">
                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                </div>

                <div id="carousel" className="scroll-smooth flex-1 flex w-full gap-4 pb-5 overflow-x-auto snap-x">
                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage:"url(/assets/project/sketch/sketch2.jpg)"}} className="h-96 w-full snap-center rounded bg-center bg-cover shadow-black/20 shadow-md"></div>
                    </div>
                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage:"url(/assets/project/sketch/sketch4.jpg)"}} className="h-96 w-full snap-center rounded bg-center bg-cover shadow-black/20 shadow-md"></div>
                    </div>
                    <div className="min-w-[80%] md:min-w-[40%]">
                        <div style={{backgroundImage:"url(/assets/project/sketch/sketch1.jpg)"}} className="h-96 w-full snap-center rounded bg-center bg-cover shadow-black/20 shadow-md"></div>
                    </div>

                    
                </div>

                <div id="next" className="flex justify-center items-center relative hidden">
                    <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                    </svg>

                </div>
            </div>
        </div>
    )
}

export default Projects;