import { Outlet } from "react-router-dom"
import Header from "./components/Header"

const Main = ()=>{
    return(
        <div className="flex flex-col min-h-screen justify-between">
            <Header/>
            <div>
                <Outlet/>
            </div>
            <div className="h-20 bg-slate-600 w-full pt-3">
                <div className="text-center text-slate-100 underline">Designed By</div>
                <div className="text-center text-slate-100 italic text-sm flex flex-col">
                    <a href="https://idevsystems.co.za/">IdevSystems (<small>https://idevsystems.co.za</small>)</a>
                    <a href="mailto:admin@idevsystems.co.za">admin@idevsystems.co.za</a>
                </div>
                
            </div>
        </div>
    )
}

export default Main;