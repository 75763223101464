import WorkItem from "../components/WorkItem";
import { Link } from "react-router-dom";


const Home = ()=>{
    return(
        <div className="pb-10">
            <div style={{backgroundImage: "url('./assets/landscape2.jpg')"}} className="h-[calc(100vh-250px)] bg-cover bg-center flex items-center justify-center">
                <div className="rounded-full shadow-lg border bg-cover bg-center h-80 w-80" style={{backgroundImage: "url('./assets/Logo.jpg')"}}>
                    {/* <h1 className="font-bold text-slate-200 text-8xl">SEBOPEGO PROJECTS</h1>
                    <h5 className="text-center md:text-lg text-slate-200 font-bold">Consulting & Projects</h5> */}
                    
                    {/* <hr className="mb-2"/>
                    <div className="pl-8 hidden">
                        <WorkItem title="Landscape Architectural Master Planning"/>
                        <WorkItem title="Environmental Planning"/>
                        <WorkItem title="Landscape Architectural Design Concepts"/>
                        <WorkItem title="Streetscape Design"/>
                        <WorkItem title="Sketch Plans and Planting Plans"/>
                        <WorkItem title="Working Drawings and As-Built Plans"/>
                        <WorkItem title="Tender Drawings"/>
                        <WorkItem title="Detail Project Costing"/>
                        <WorkItem title="Project Management"/>
                        <WorkItem title="Urban Design and Layouts"/>
                        <WorkItem title="Council Submissions and Approvals"/>
                    </div> */}
                </div>
            </div>

            <div className="max-w-screen-2xl mx-auto pt-10 px-5">
                <div className="mb-4">
                    <text className="bg-lime-600 py-1 px-3 font-bold text-slate-200 md:text-4xl rounded text-2xl">SEBOPEGO PROJECTS</text>
                    <div className="py-3 md:text-xl px-4 border-t-2 border-lime-500">
                        <span className="">Sebopego</span> consulting and projects is a contracting and consulting company based in Gauteng South Africa, delivering services throughout the country and internationally.
                        We specialise in delivering services that includes Landscape Architecture, Planning, Construction and Project Management services. We have worked on projects of variety 
                        scales, ranging from large multi-functional sites to small intimate spaces. Each of our project is unique and responsive to the land, based on our client's needs.
                    </div>

                    <div className="py-3 md:text-xl mt-5 px-4">
                        The company works at multiple scales and with various organizations right from private clients, corporates, government and NGOs. This allows us to experiment and diversify 
                        works. Our approach aims to transform and reinvent living environments and urban spaces.
                    </div>

                    <div className="py-3 md:text-2xl text-center mt-10">
                        We are registered with SACLAP, SALI, ILASA, Central Supplier Database, CIBD and we are Level 1 B-BBEE contributors.
                    </div>
                </div>

                <div className="flex justify-center items-center mt-10 mb-10">
                    <div className="border rounded-lg py-2 px-6 text-center bg-lime-600 font-bold text-slate-200 cursor-pointer"><Link to="projects" >Our Services</Link></div>
                </div>

            </div>

            <div className="mt-4 max-w-screen-2xl mx-auto p-5 px-8">
                <div className="grid md:grid-cols-2 grid-cols-1 shadow-xl rounded-sm border">
                    <div className="flex w-full md:flex-row flex-col">
                        <div className="bg-lime-900 py-2  flex md:flex-col justify-center items-center">
                            <h2 className="text-center px-4 font-bold text-slate-200">M</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">I</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">S</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">S</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">I</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">O</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">N</h2>
                        </div>
                        <div style={{backgroundImage: "url(./assets/landscape.jpg)"}} className="w-full md:hidden h-60 bg-cover bg-center"></div>
                        <div className="shadow-lg px-5 flex items-center md:text-lg">
                            To offer innovative solutions to meet the objectives and requirements of our clients.<br/>
                            To deliver value added services which are, at all times, guided by our client's needs
                        </div>
                    </div>
                    <div style={{backgroundImage: "url(./assets/landscape.jpg)"}} className="w-full md:h-96 h-60 bg-cover bg-center hidden md:block">
                    </div>

                    <div className="bg-lime-900 py-2 flex md:hidden mt-2 justify-center items-center">
                        <h2 className="text-center px-4 font-bold text-slate-200">V</h2>
                        <h2 className="text-center px-4 font-bold text-slate-200">I</h2>
                        <h2 className="text-center px-4 font-bold text-slate-200">S</h2>
                        <h2 className="text-center px-4 font-bold text-slate-200">I</h2>
                        <h2 className="text-center px-4 font-bold text-slate-200">O</h2>
                        <h2 className="text-center px-4 font-bold text-slate-200">N</h2>
                    </div>

                    <div style={{backgroundImage: "url(./assets/vision.jpg)"}} className="w-full md:h-96 h-60 bg-cover bg-center">

                    </div>
                    <div className="flex md:flex-row flex-col">
                        
                        <div className="shadow-lg px-5 flex items-center md:text-lg">
                            To be the leading construction company in our chosen markets, sought after by potential clients and employees for our track record 
                            in reliable execution, cost effectiveness and world class technical sophistication.
                        </div>

                        <div className="bg-lime-900 py-2 hidden md:flex flex-col justify-center items-center">
                            <h2 className="text-center px-4 font-bold text-slate-200">V</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">I</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">S</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">I</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">O</h2>
                            <h2 className="text-center px-4 font-bold text-slate-200">N</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;