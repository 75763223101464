import { Link } from "react-router-dom";
import { useState } from "react";

const Header = ()=>{
    const [menuVisibility, setMenuVisibility] = useState(false);
    return(
        <header className="bg-gradient-to-r from-lime-700 via-lime-500 to-lime-900 shadow  relative">
            <nav className="flex justify-between max-w-screen-2xl mx-auto sticky px-3 py-4 md:py-0 md:px-2">
                {/* Logo */}
                <div className="flex items-center">
                    <svg className="w-10 h-10 mr-1 text-yellow-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
                    </svg>

                    <Link to="/" className="text-lg font-bold text-slate-200">SEBOPEGO PROJECTS</Link>

                </div>
                {/* Menu */}
                <div className="hidden md:flex space-x-1">
                    <div className="menu-item">
                        <Link to="/" className="text-slate-200">HOME</Link>
                    </div>
                    <div className="menu-item">
                        <Link to="projects" className="text-slate-200">PROJECTS</Link>
                    </div>
                    {/* <div className="menu-item">
                        <Link to="team" className="text-slate-200">TEAM</Link>
                    </div> */}
                    <div className="menu-item">
                        <Link to="contact" className="text-slate-200">CONTACT</Link>
                    </div>
                </div>

                {/* Mobile Menu */}
                <div className="md:hidden flex items-center" onClick={()=>setMenuVisibility(!menuVisibility)}>
                    <svg className="w-6 h-6 text-slate-200 mx-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>

                </div>

            </nav>
            {menuVisibility && 
                <div className="md:hidden absolute flex flex-col bg-lime-900 w-full">
                    <div className="menu-item">
                        <Link to="/" className="text-slate-200">HOME</Link>
                    </div>
                    <div className="menu-item">
                        <Link to="projects" className="text-slate-200">PROJECTS</Link>
                    </div>
                    {/* <div className="menu-item">
                        <Link to="team" className="text-slate-200">TEAM</Link>
                    </div> */}
                    <div className="menu-item">
                        <Link to="contact" className="text-slate-200">CONTACT</Link>
                    </div>
                </div>
            }

            
        </header>
    )
}

export default Header;