import Facebook from "../assets/facebook.png";
import LinkedIn from "../assets/linkedin.png";
import Instagram from "../assets/instagram.png";

const Contact = ()=>{
    return(
        <div className="max-w-screen-2xl mx-auto md:pt-10 md:px-10 px-2 py:2">
            <div className="border-b-2  border-lime-500">
                <text className="bg-lime-600 py-1 px-3 font-bold text-slate-200 md:text-4xl text-xl">CONTACT US</text>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2">
                <div style={{backgroundImage: "url('./assets/Logo.jpg')"}} className="bg-cover bg-center h-40 md:h-80 w-80"></div>
                <div className="flex justify-center items-center md:mt-10 flex-col">
                    {/* <svg className="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                    </svg> */}
                    <h3 className="font-bold md:text-2xl text-xl">Info@sebopegoprojects.co.za</h3>
                    <h3 className="font-bold md:text-2xl text-xl">+277 613 8520</h3>
                    <div className="flex space-x-1 mt-4">
                        <a href="https://www.linkedin.com/company/concrete-eight-landscape-architecture-planning-construction-pty-ltd/about/">
                            <img src={LinkedIn} alt="LinkedIn" width="50" />
                        </a>
                        <a href="https://www.instagram.com/sebopego_projects/">
                            <img src={Instagram} alt="Instagram" width="50" />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100063523285418&paipv=0&eav=AfZIL2QKyGNaheDFNfREiwatO75P5edkytFgAvFSldssUl7KNV3Dq3Db2_l0I5nQxf4">
                            <img src={Facebook} alt="Facebook" width="50" />
                        </a>
                    </div>

                </div>
            </div>
            
            {/* <div className="grid grid-cols-2 mt-5">
                <div>
                    <h3 className="text-center font-bold">OUR VALUES AND CORPORATE RESPONSIBILITY</h3>
                    <div className="border border-lime-900 mt-1 pb-4">
                        <h4 className="font-bold bg-lime-900 text-slate-200 p-1 text-center">Excellence</h4>
                        <p className="text-center">
                            We strive for excellence in quality & we continuously innovate, utilizing advanced programs.
                        </p>
                    </div>

                    <div className="border border-lime-900 mt-1 pb-4">
                        <h4 className="font-bold bg-lime-900 text-slate-200 p-1 text-center">Teamwork</h4>
                        <p className="text-center">
                            We insist on mutual respect, co-operation and mutual encouragement to achieve each individual's potential.
                        </p>
                    </div>

                    <div className="border border-lime-900 mt-1 pb-4">
                        <h4 className="font-bold bg-lime-900 text-slate-200 p-1 text-center">Integrity</h4>
                        <p className="text-center">
                            We act with reliability, honesty and fairness
                        </p>
                    </div>

                    <div className="border border-lime-900 mt-1 pb-4">
                        <h4 className="font-bold bg-lime-900 text-slate-200 p-1 text-center">Commitment</h4>
                        <p className="text-center">
                            We are fully dedicated to delivering our objectives through the most efficient use of resources
                        </p>
                    </div>

                    <div className="border border-lime-900 mt-1 pb-4">
                        <h4 className="font-bold bg-lime-900 text-slate-200 p-1 text-center">Sustainability</h4>
                        <p className="text-center">
                            We are committed to safeguarding all stakeholders, which includes the health and safety of our employees, society as a whole and environment.
                        </p>
                    </div>

                    <div className="border border-lime-900 mt-1 pb-4">
                        <h4 className="font-bold bg-lime-900 text-slate-200 p-1 text-center">Accountability</h4>
                        <p className="text-center">
                            We are fully accountable for carrying out our responsibilities in accordance to the values and priniciples of Sebopego Consulting and Projects.
                        </p>
                    </div>
                </div>

                
            </div> */}
        </div>
    )
}

export default Contact;